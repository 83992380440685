import PhotoAlbum from "react-photo-album";
import one from '../img/1.jpeg';
import two from '../img/two.jpg';
import three from '../img/three.jpg';
import four from '../img/four.jpg';
import five from '../img/five.jpg';
import six from '../img/six.jpg';
import seven from '../img/seven.jpg';
import eight from '../img/eight.jpg';
import {Container, Typography} from '@mui/material';
import React from "react";

const photos = [
    {
        src: one,
        width: 800,
        height: 600,
        srcSet: [
            { src: one, width: 400, height: 300 }
        ],
    },
    {
        src: two,
        width: 800,
        height: 600,
        srcSet: [
            { src: two, width: 800, height: 450 }
        ],
    },
    {
        src: three,
        width: 800,
        height: 600,
        srcSet: [
            { src: three, width: 800, height: 450 }
        ],
    },
    {
        src: four,
        width: 800,
        height: 600,
        srcSet: [
            { src: four, width: 800, height: 450 }
        ],
    },
    {
        src: five,
        width: 800,
        height: 600,
        srcSet: [
            { src: five, width: 800, height: 450 }
        ],
    },
    {
        src: six,
        width: 800,
        height: 600,
        srcSet: [
            { src: six, width: 800, height: 450 }
        ],
    },
    {
        src: seven,
        width: 800,
        height: 600,
        srcSet: [
            { src: seven, width: 800, height: 450 }
        ],
    },
    {
        src: eight,
        width: 800,
        height: 600,
        srcSet: [
            { src: eight, width: 800, height: 450 }
        ],
    }
];

const Gallery = () => (
    <Container style={{
        'background': 'rgb(255,255,255,.7)',
        'padding-top': '20px'
    }}>
        <PhotoAlbum layout="rows" photos={photos} />

    </Container>
    );
export default Gallery;