// src/components/Home.js
import React from 'react';
import {Container, useMediaQuery} from '@mui/material';
import mainLogo from '../img/MAIN_LOGO.png';
import newLogo from '../img/new-logo2.png';
import Box from "@mui/material/Box";
import { keyframes } from '@emotion/react';
const Home = () => {
    const isMobileSmall = useMediaQuery('(max-width:915px)');

    const fadeIn
        = keyframes`
              from {
                opacity: 0;
                transform: translateY(20px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            `;

    return(
        <Container >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '90vh', // Full viewport height,
                    opacity: 0, // Ensure initial state is hidden
                    animation: `${fadeIn} 1s ease-out`,
                    animationDelay: '1s', // Add a 2-second delay
                    animationFillMode: 'forwards',
                }}
            >
                {!isMobileSmall ? (
                    <Box component="img"
                     src={newLogo}
                     alt="Logo"
                     style={{width: '90vh', height: '90vh'}}
                     sx={{ mr: 2 ,
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center'}} />
                    ):(
                    <Box component="img"
                    src={newLogo}
                    alt="Logo"
                    style={{width: '43vh', height: '43vh'}}
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}} />
                    )}
            </Box>
        </Container>
    );
};

export default Home;

